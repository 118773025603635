export default function prepareRecaptcha(recaptchaSiteKey) {
    return new Promise(resolve => {
        let recaptchaReady = false;
        window.recaptchaLoaded = function () {
            recaptchaReady = true;
        };

        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=' + recaptchaSiteKey + '&onload=recaptchaLoaded';
        script.async = true;
        document.head.appendChild(script);

        const checkRecaptchaReady = setInterval(() => {
            if (recaptchaReady) {
                clearInterval(checkRecaptchaReady);
                resolve();
            }
        }, 100);
    });
}
